import { gql } from '@apollo/client'

import { NAVIGATION_DRAWER_VIEWER_FRAGMENT } from '@components/header_navigation_drawer/constants'
import { HEADER_SECONDARY_NAV_VIEWER_FRAGMENT } from '@components/header_secondary_nav/constants'
import { PROFILE_IMAGE_VIEWER_FRAGMENT } from '@components/profile_image/constants'

export const HEADER_TOP_VIEWER_FRAGMENT = gql`
  fragment HeaderTopViewer on AccountsViewer {
    ...HeaderSecondaryNavViewer
    ...NavigationDrawerViewer
    ...ProfileImageViewer
  }
  ${HEADER_SECONDARY_NAV_VIEWER_FRAGMENT}
  ${NAVIGATION_DRAWER_VIEWER_FRAGMENT}
  ${PROFILE_IMAGE_VIEWER_FRAGMENT}
`

export const IMG_SIZE = '20px'
export const ACCOUNT_MENU_FALLBACK = 'Account menu'

import ArrowShortIcon from '@thebeansgroup/ui/icons/short_arrow'
import Typography from '@thebeansgroup/ui/typography'

import { trackUnstructEvent } from '@root/src/tracker/snowplow'

import {
  BROWSER_EXT_BANNER_URL,
  BROWSER_EXT_BANNER_CLICK_SCHEMA
} from '@constants/browser_extension'
import { useCCG } from '@hooks/use_ccg'
import { useCountryCode } from '@hooks/use_country_code'
import { useI18n } from '@hooks/use_i18n'

import {
  BROWSER_EXT_BANNER_TEXT_FALLBACK,
  POSITION_ON_PAGE,
  GO_TO_STUDENT_BEANS_EXTENSION_FALLBACK
} from './constants'
import { shouldRenderBanner } from './helpers'

const BrowserExtBanner = () => {
  const country = useCountryCode()
  const { isGradBeansPage } = useCCG()
  const { t } = useI18n()

  if (!shouldRenderBanner(country, isGradBeansPage)) return null

  const handleClick = () => {
    trackUnstructEvent(BROWSER_EXT_BANNER_CLICK_SCHEMA, {
      country_code: country,
      location_on_page: POSITION_ON_PAGE
    })
  }

  const renderGradient = () => {
    return (
      <div
        role='presentation'
        className='gradient-lavender-field z-0 absolute top-1/2 -translate-y-1/2 h-[1030px] w-full'
      />
    )
  }

  return (
    <a
      href={BROWSER_EXT_BANNER_URL}
      className='hidden relative overflow-hidden lg:block'
      target='_blank'
      rel='noreferrer'
      onClick={handleClick}
    >
      <div className='relative flex-center gap-3 w-full z-1 h-7'>
        <Typography
          variant='headline'
          component='h2'
          fontWeight='semibold'
          className='text-white-300'
        >
          {t('a_chrome_ext_sitewide_banner', {
            fallback: BROWSER_EXT_BANNER_TEXT_FALLBACK
          })}
        </Typography>
        <ArrowShortIcon
          size={16}
          colour='white-300'
          label={t('y_go_to_student_beans_extension', {
            fallback: GO_TO_STUDENT_BEANS_EXTENSION_FALLBACK
          })}
        />
      </div>
      {renderGradient()}
    </a>
  )
}

export default BrowserExtBanner

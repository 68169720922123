'use client'

import PropTypes from 'prop-types'
import { createContext } from 'react'

import { useQuery } from '@hooks/use_query'

export const UserDataContext = createContext({
  data: null,
  error: null,
  loading: true
})

export const UserDataProvider = ({ children, query, params }) => {
  const { loading, error, data } = useQuery(query, {
    ssr: false,
    variables: params,
    errorPolicy: 'all'
  })

  return (
    <UserDataContext.Provider
      value={{
        data,
        error,
        loading
      }}
    >
      {children}
    </UserDataContext.Provider>
  )
}

UserDataProvider.propTypes = {
  children: PropTypes.any.isRequired,
  query: PropTypes.any.isRequired,
  params: PropTypes.object
}

UserDataProvider.defaultProps = {
  params: {}
}

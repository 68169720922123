import { UserVerification } from '@typings/graphql/graphql'
import {
  TUserProfileEmail,
  TUserProfileVerifications
} from '@typings/user_data'
import { Nullable } from '@typings/utils'

export const getUserEmail = (
  data?: Nullable<TUserProfileEmail>
): Nullable<string> => data?.accountsViewer?.userProfile?.email ?? null

export const getUserVerifications = (
  data?: Nullable<TUserProfileVerifications>
): Nullable<UserVerification[]> | [] =>
  data?.accountsViewer?.userProfile?.verifications ?? []

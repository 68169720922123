import {
  CAMPAIGN_STATUS,
  BLOCK_TYPES,
  GRADIENTS_MAP,
  TEXT_COLOUR_MAP,
  TYPE_CAMPAIGN,
  TYPE_COLLECTION,
  TYPE_OFFER,
  MODULE_TYPES,
  TYPE_EDITORIAL,
  ICON_COLOUR_MAP
} from '@constants/campaigns'

import { getNextRoute } from './routes'

export const getBlocksFromLocalNode = (local) =>
  local?.edges?.map((edge) => edge?.node)

export const getBlockType = (blocks) => {
  if (blocks?.length > 1) return BLOCK_TYPES.HEADER

  return BLOCK_TYPES.CTA
}

export const isHeader = (blocks) => getBlockType(blocks) === BLOCK_TYPES.HEADER

export const isHeroType = (type) => type === MODULE_TYPES.HERO

export const getForegroundBlock = (blocks) =>
  blocks?.find((block) => block?.type === BLOCK_TYPES.HEADER)

export const getBackgroundBlock = (blocks) =>
  blocks?.find((block) => block?.type === BLOCK_TYPES.BACKGROUND)

export const getStandardOfferName = (blocks, t) =>
  blocks?.[0]?.discoveryCollection?.name ??
  t('d_exclusive_student_discounts', {
    fallback: 'Exclusive Student Discounts'
  })

export const getOfferDescription = (blocks) =>
  blocks?.[0]?.discoveryCollection?.description ?? ''

export const getOffersFromBlocks = (blocks) =>
  blocks?.[0]?.discoveryCollection?.collectionOffers ?? null

export const convertSlugToSnakeCase = (slug) => slug.replace(/-/g, '_')

export const getOfferTileSuffix = (slug) =>
  `?source=${convertSlugToSnakeCase(slug)}`

export const isCampaignBanner = (promo) => {
  return !!promo?.campaign?.countrySlug && !!promo?.campaign?.campaignSlug
}

export const isCollectionBanner = (promo) => {
  return !!promo?.collection?.slug && !!promo?.collection?.countrySlug
}

export const isOfferBanner = (promo) => {
  return (
    !!promo?.offer?.brand?.slug &&
    !!promo?.offer?.country?.slug &&
    !!promo?.offer?.slug
  )
}

export const isEditorialBanner = (promo) => {
  return !!promo?.cta && !!promo?.url
}

export const getPromoBanner = (promo, source) => {
  const promoType = getPromoBannerType(promo)

  if (!promo?.[promoType]) return null

  return {
    url: constructPromoUrl(promo, source),
    text: promo.title,
    subtitle: promo.description,
    styleConfig: getPromoStyles(promo)
  }
}

export const constructPromoUrl = (promo, source) => {
  const sourceParameter = `?source=${source}`

  if (isCampaignBanner(promo)) {
    return (
      getNextRoute('campaign.show', {
        country: promo.campaign.countrySlug,
        slug: promo.campaign.campaignSlug
      }) + sourceParameter
    )
  }

  if (isCollectionBanner(promo)) {
    return (
      getNextRoute('collection.show', {
        country: promo.collection.countrySlug,
        collection: promo.collection.slug
      }) + sourceParameter
    )
  }

  if (isOfferBanner(promo)) {
    const offerParameter = `&offer=${promo.offer.slug}`

    return (
      getNextRoute('brand.show', {
        country: promo.offer.country.slug,
        brand: promo.offer.brand.slug,
        offer: promo.offer.slug
      }) +
      sourceParameter +
      offerParameter
    )
  }

  if (isEditorialBanner(promo)) {
    return promo.url + sourceParameter
  }

  return null
}

const hasValidPromoType = (promo) => {
  return (
    isCampaignBanner(promo) ||
    isCollectionBanner(promo) ||
    isOfferBanner(promo) ||
    isEditorialBanner(promo)
  )
}

export const hasValidPromo = (promo) => {
  if (!hasValidPromoType(promo)) return false

  const promoType = getPromoBannerType(promo)

  if (promoType === TYPE_EDITORIAL) return isPromoLiveAndInDate(promo)

  return isPromoLiveAndInDate(promo[promoType])
}

export const getPromoBannerType = (promo) => {
  if (isCampaignBanner(promo)) return TYPE_CAMPAIGN

  if (isCollectionBanner(promo)) return TYPE_COLLECTION

  if (isOfferBanner(promo)) return TYPE_OFFER

  if (isEditorialBanner(promo)) return TYPE_EDITORIAL

  return null
}

export const getPromoStyles = (promo) => {
  if (!promo?.stylingConfig) return null

  const styles = JSON.parse(promo.stylingConfig)
  return {
    gradient: GRADIENTS_MAP[styles.gradient],
    backgroundColour: styles.backgroundColour,
    textColour: TEXT_COLOUR_MAP[styles.textColour],
    iconColour: ICON_COLOUR_MAP[styles.textColour],
    image: styles.image
  }
}

export const isInDate = (startDate, endDate) => {
  const now = new Date()
  const start = new Date(startDate)
  const end = new Date(endDate)

  if (!startDate) return false

  if (!endDate) return now > start

  return now > start && now < end
}

export const isPromoLiveAndInDate = (promo) => {
  const isLive =
    promo?.status?.toLowerCase() === 'live' ||
    promo?.status?.toLowerCase() === 'boosted'

  if (!isLive || !isInDate(promo.startDate, promo.endDate)) return false

  return true
}

export const getPromoBannerData = (promoBlocks, promoType) => {
  const byType = (promo) => promo?.node?.promoBlockType === promoType
  const bannerData = promoBlocks?.filter(byType)

  return bannerData?.[0]?.node.promoItems?.edges?.[0]?.node
}

export const hasPromoBackgroundImage = (image) => {
  return !!(image?.desktop?.png || image?.mobile?.png)
}

export const shouldCampaign404 = (campaign) => {
  if (!campaign) return true

  if (campaign.evergreen) return false

  return !isCampaignLive(campaign)
}

export const getPromoItemsByBlockType = (promoBlocks, promoType) => {
  const filteredPromoBlock = promoBlocks?.filter((promo) => {
    return promo?.node?.promoBlockType === promoType
  })
  return filteredPromoBlock[0]?.node?.promoItems?.edges
}

export const shouldRenderCountdown = (campaign) => {
  return campaign.evergreen && isCampaignScheduled(campaign)
}

export const shouldRenderNonEvergreenContent = (campaign) => {
  return isCampaignLive(campaign)
}

export const shouldRenderConfetti = (campaign) => {
  return (
    isCampaignLive(campaign) &&
    new Date(campaign.startDate).toDateString() === new Date().toDateString()
  )
}

export const isCampaignLive = (campaign) => {
  const now = new Date()
  return !!(
    campaign.status === CAMPAIGN_STATUS.live ||
    (now > new Date(campaign.startDate) && now < new Date(campaign.endDate))
  )
}

export const isCampaignScheduled = (campaign) =>
  campaign.status === CAMPAIGN_STATUS.scheduled &&
  new Date() < new Date(campaign.startDate)

export const shouldRenderMarkdownContent = (markdown) => !!markdown

export const getTargetDate = (campaign) => {
  return new Date(campaign.startDate)
}

export const hasHero = (campaign) => {
  return campaign?.modules?.edges.some((module) => isHeroType(module.node.type))
}

export const shouldRenderHeader = (campaign) => {
  return !hasHero(campaign)
}

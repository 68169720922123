import { getDeviceType } from '@helpers/device_type'

import { DEFAULT_VISITOR_DATA, PLATFORM } from './constants'

export const generateVisitorData = (loading, country, data) => {
  if (loading || !country) return null

  const upperCaseCountryCode = country.toLowerCase()
  const sbid = getSbid(data)

  if (!sbid) return createAnonymousVisitor(upperCaseCountryCode)

  return createVisitor(upperCaseCountryCode, sbid, data)
}

export const createVisitor = (country, sbid, data) => ({
  id: sbid,
  isAuthenticated: true,
  hasConsented: true,
  context: {
    platform: PLATFORM,
    country,
    sdk_deviceType: getDeviceType()
  }
})

export const createAnonymousVisitor = (country) => ({
  ...DEFAULT_VISITOR_DATA,
  context: {
    platform: PLATFORM,
    country,
    sdk_deviceType: getDeviceType()
  }
})

export const getSbid = (data) =>
  data?.accountsViewer?.userProfile?.idCode ?? null

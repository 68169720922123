import {
  ACTIVE_VERIFICATION_STATUS,
  CCGS,
  VALID_CCGS
} from '@constants/closed_consumer_groups'
import { UserVerification } from '@typings/graphql/graphql'
import { TUserProfileVerifications } from '@typings/user_data'

import { getUserVerifications } from './user_data'

export const isValidCCG = (consumerGroup: string): boolean =>
  (VALID_CCGS as string[]).includes(consumerGroup)

export const isGraduate = (consumerGroup: string): boolean =>
  consumerGroup === CCGS.GRADUATE

export const isUserVerifiedGraduate = (
  data: TUserProfileVerifications
): boolean => {
  const verifications = getUserVerifications(data)

  return !!verifications?.some(
    ({ consumerGroup, status }: UserVerification) =>
      consumerGroup === CCGS.GRADUATE && status === ACTIVE_VERIFICATION_STATUS
  )
}

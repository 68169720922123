import { ZENDESK_SETUP } from '@constants/zendesk'
import { getIsLoggedIn } from '@helpers/auth'
import { getCountryOptions } from '@helpers/countries'
import FeatureToggle from '@helpers/feature_toggle'

import { EMPTY_INPUT } from './constants'

export const shouldLoadScript = (loading, data, country) => {
  const isLoggedIn = getIsLoggedIn(data)

  if (!FeatureToggle.isEnabled('zendesk_chat')) return false

  const { hasZendeskChat } = getCountryOptions(country)
  if (!hasZendeskChat) return false

  return !loading && isLoggedIn
}

export const prefillUserDetails = (data) => {
  const { email, firstName, lastName } = data?.accountsViewer?.userProfile ?? {}

  window?.zE?.('webWidget', 'identify', {
    name: firstName && lastName ? `${firstName} ${lastName}` : EMPTY_INPUT,
    email: email ?? EMPTY_INPUT
  })
}

export const handleScriptLoad = (data) => {
  prefillUserDetails(data)
  window?.zE?.('webWidget', 'hide')
  document.dispatchEvent(new window.CustomEvent(ZENDESK_SETUP))
}

import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

import { isGraduate } from '@helpers/closed_consumer_groups'

import { getCCGFromPath } from './helpers'

export const useCCG = () => {
  const pathname = usePathname()

  const ccg = useMemo(() => getCCGFromPath(pathname), [pathname])
  const isGradBeansPage = useMemo(() => isGraduate(ccg), [ccg])

  return {
    ccg,
    isGradBeansPage
  }
}

import { gql } from '@apollo/client'

import { PROFILE_SECTION_VIEWER_FRAGMENT } from '@components/header_profile_section/constants'

export const NAVIGATION_DRAWER_VIEWER_FRAGMENT = gql`
  fragment NavigationDrawerViewer on AccountsViewer {
    isLoggedIn
    ...ProfileSectionViewer
  }
  ${PROFILE_SECTION_VIEWER_FRAGMENT}
`

export const CLOSE_FALLBACK = 'Close'

import { ApolloProvider } from '@apollo/client'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

import { client } from '@root/apollo_clients/web/csr'

import Container from '@components/container'
import ErrorBoundary from '@components/error_boundary'
import FlagshipProvider from '@components/flagship_provider'
import CampaignBanner from '@components/header_campaign_banner'
import { SnowplowProvider } from '@components/snowplow_provider'
import { UserDataProvider } from '@components/user_data_provider'
import ZendeskChat from '@components/zendesk'
import { useCountryCode } from '@hooks/use_country_code'

const Footer = dynamic(() => import('@components/footer'))

const AppDownloadBannerDismissable = dynamic(
  () => import('@components/app_download_banner_dismissable'),
  { ssr: false }
)

const Header = dynamic(() => import('@components/header'))

const PageView = dynamic(() => import('@components/page_view'), { ssr: false })

const SearchInsights = dynamic(() => import('@components/search_insights'))

const Page = ({
  children,
  displayAppDownloadBanner,
  query,
  queryParams,
  pageLabel,
  fullWidth,
  customContexts,
  categoryName,
  subCategoryName,
  brandName,
  promoBlocks,
  className
}) => {
  const country = useCountryCode()

  return (
    <Fragment>
      <ApolloProvider client={client}>
        <UserDataProvider
          query={query}
          params={queryParams}
        >
          <ZendeskChat />
          <FlagshipProvider>
            <SnowplowProvider>
              <div className='flex flex-col min-h-screen relative overflow-x-hidden overflow-y-auto'>
                <AppDownloadBannerDismissable country={country} />
                <CampaignBanner promoBlocks={promoBlocks} />
                <Header promoBlocks={promoBlocks} />
                <main className={clsx('flex-1 pb-7', className)}>
                  <ErrorBoundary country={country}>
                    <Container fullWidth={fullWidth}>{children}</Container>
                  </ErrorBoundary>
                </main>
                <Footer displayAppDownloadBanner={displayAppDownloadBanner} />
              </div>
              <PageView
                customContexts={customContexts}
                pageLabel={pageLabel}
                categoryName={categoryName}
                subCategoryName={subCategoryName}
                brandName={brandName}
              />
            </SnowplowProvider>
          </FlagshipProvider>
        </UserDataProvider>
        <SearchInsights />
      </ApolloProvider>
    </Fragment>
  )
}

Page.propTypes = {
  children: PropTypes.any.isRequired,
  displayAppDownloadBanner: PropTypes.bool,
  pageLabel: PropTypes.string,
  fullWidth: PropTypes.bool,
  query: PropTypes.any.isRequired,
  queryParams: PropTypes.object,
  customContexts: PropTypes.arrayOf(PropTypes.object),
  categoryName: PropTypes.string,
  subCategoryName: PropTypes.string,
  brandName: PropTypes.string,
  promoBlocks: PropTypes.array,
  className: PropTypes.string
}

Page.defaultProps = {
  displayAppDownloadBanner: false,
  fullWidth: false,
  queryParams: {},
  customContexts: [],
  pageLabel: '',
  categoryName: '',
  subCategoryName: '',
  brandName: '',
  promoBlocks: null,
  className: 'bg-white-300'
}

export default Page

export const MAX_MOBILE_WIDTH = 779

export const DEVICE_TYPE = {
  mobile: 'mobile',
  desktop: 'desktop'
}

export const ANDROID = 'android'
export const IOS = 'ios'
export const OS_NOT_FOUND = 'not found'

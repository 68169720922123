import { FlagshipProvider as FSProvider } from '@flagship.io/react-sdk'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { useCountryCode } from '@hooks/use_country_code'
import { useUserData } from '@hooks/use_user_data'

import { generateVisitorData } from './helpers'

const FlagshipProvider = ({ children }) => {
  const country = useCountryCode()
  const { loading, data } = useUserData()

  const visitorData = useMemo(
    () => generateVisitorData(loading, country, data),
    [loading, data, country]
  )

  return (
    <FSProvider
      envId={process.env.NEXT_PUBLIC_FLAGSHIP_ENV}
      apiKey={process.env.NEXT_PUBLIC_FLAGSHIP_KEY}
      visitorData={visitorData}
      enableClientCache={process.env.NEXT_PUBLIC_FLAGSHIP_CLIENT_CACHE}
      logLevel={process.env.NEXT_PUBLIC_FLAGSHIP_LOG_LEVEL}
    >
      {children}
    </FSProvider>
  )
}

FlagshipProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default FlagshipProvider

import {
  MAX_MOBILE_WIDTH,
  DEVICE_TYPE,
  ANDROID,
  IOS,
  OS_NOT_FOUND
} from '@constants/device_type'

export const getDeviceType = (): string => {
  if (screen.width > MAX_MOBILE_WIDTH) return DEVICE_TYPE.desktop

  return DEVICE_TYPE.mobile
}

export const getDeviceOS = (): string => {
  const { userAgent } = window.navigator

  if (userAgent.match(/Android/i)) return ANDROID

  if (userAgent.match(/iPhone|iPad|iPod/i)) return IOS

  return OS_NOT_FOUND
}

import { NO_INDEX } from '@constants/metadata'
import { withAssetPrefix } from '@helpers/assets'
import { getPublishRoute } from '@helpers/routes'

import {
  DEFAULT_TITLE,
  DESCRIPTION_SCHEMA_FALLBACK,
  GRAD_BEANS_FAVICON,
  STUDENT_BEANS_FAVICON
} from './constants'

export const getRobotsContent = (robots = []) => {
  if (shouldAddNoIndex(robots)) robots.push(NO_INDEX)
  return robots.join(', ')
}

export const shouldAddNoIndex = (robots) => {
  if (robots.includes(NO_INDEX)) return false

  return process.env.NEXT_PUBLIC_APP_ENV === 'staging'
}

export const getTitle = (title) => title || DEFAULT_TITLE

export const formatLdData = (data, t) => {
  const graphData = data.map((item) => {
    if (item?.type === 'Organisation') return getLdOrganisationData(t)

    if (item?.type === 'BreadcrumbList') return getLdBreadcrumbListData(item)

    if (item?.type === 'FAQPage') return getLdFaqData(item)

    if (item?.type === 'Product') return getLdProductData(item)

    return null
  })

  return {
    '@context': 'https://schema.org',
    '@graph': graphData
  }
}
export const getDescription = (t) => {
  return t('m_how_it_works_markup', {
    fallback: DESCRIPTION_SCHEMA_FALLBACK
  })
}

const getLdOrganisationData = (t) => {
  return {
    '@type': 'Organization',
    url: getPublishRoute('home.base'),
    logo: withAssetPrefix('images/sb_square_logo.png'),
    name: 'Student Beans',
    sameAs: [
      'https://instagram.com/studentbeans',
      'https://www.tiktok.com/@studentbeans',
      'https://www.youtube.com/@Student_Beans',
      'https://play.google.com/store/apps/details?id=com.studentbeans.studentbeans&hl=gb&referrer=utm_source%3Dstudentbeans%26utm_medium%3Dapp_page ',
      'https://itunes.apple.com/gb/app/apple-store/id994482161?pt=10031819&ct=studentbeans-app_page&mt=8',
      'https://chrome.google.com/webstore/detail/student-beans/pdmhehfogekmpmdoemhabjpaiadagpgp   '
    ],
    description: getDescription(t),
    address: {
      streetAddress: '1 Vincent Square, London, SW1 2PN'
    },
    foundingDate: 2005
  }
}

export const getLdBreadcrumbListData = (data) => {
  return {
    '@type': 'BreadcrumbList',
    itemListElement: data?.list.map((breadcrumb, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        name: breadcrumb?.breadcrumbName,
        item: breadcrumb?.breadcrumbUrl
      }
    }),
    name: 'Breadcrumbs'
  }
}

export const getLdFaqData = (data) => {
  return {
    '@type': 'FAQPage',
    mainEntity: data?.list.map((faq) => {
      return {
        '@type': 'Question',
        name: faq?.faqQuestion,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq?.faqAnswer
        }
      }
    }),
    name: 'FAQs'
  }
}

export const getLdProductData = (data) => {
  return data?.list.map((discount) => {
    return {
      '@type': 'Product',
      name: discount.discountName,
      brand: {
        '@type': 'Brand',
        name: discount.brandName
      },
      description: discount.discountDescription,
      image: discount.discountImage,
      offers: {
        '@type': 'Offer',
        discount: discount.discountAmount
      }
    }
  })
}

export const getFavIcon = (isGradBeansPage) => {
  if (isGradBeansPage) return withAssetPrefix(GRAD_BEANS_FAVICON)

  return withAssetPrefix(STUDENT_BEANS_FAVICON)
}

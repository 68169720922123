import { getCountryOptions } from '@helpers/countries'

export const shouldRenderBanner = (country, isGradBeansPage) => {
  const { hasBrowserExtension } = getCountryOptions(country)

  if (!hasBrowserExtension) return false

  if (isGradBeansPage) return false

  return true
}

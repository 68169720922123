import { gql } from '@apollo/client'

import { PROFILE_MENU_VIEWER_FRAGMENT } from '@components/header_profile_menu/constants'

export const HEADER_SECONDARY_NAV_VIEWER_FRAGMENT = gql`
  fragment HeaderSecondaryNavViewer on AccountsViewer {
    id
    isLoggedIn
    userProfile {
      id
      idCode
      avatar {
        thumbnailUrl
      }
      firstName
      lastName
      verified
    }
    ...ProfileMenuViewer
  }
  ${PROFILE_MENU_VIEWER_FRAGMENT}
`

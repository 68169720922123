import { gql } from '@apollo/client'

export const PROFILE_SECTION_VIEWER_FRAGMENT = gql`
  fragment ProfileSectionViewer on AccountsViewer {
    id
    userProfile {
      id
      firstName
      lastName
      verified
    }
  }
`

export const IMG_SIZE = '38px'
export const LOGOUT_FALLBACK = 'Log Out'
export const MY_ACCOUNT_FALLBACK = 'My Account'

import PropTypes from 'prop-types'
import { useState, useEffect, createContext } from 'react'

import { SNOWPLOW_IS_SETUP } from '@constants/snowplow'

export const SnowplowContext = createContext({ isSnowplowSetup: false })

export const SnowplowProvider = ({ children }) => {
  const [isSnowplowSetup, setIsSnowplowSetup] = useState(false)

  useEffect(() => {
    const handleSnowplowSetup = () => {
      setIsSnowplowSetup(true)
    }

    document.addEventListener(SNOWPLOW_IS_SETUP, handleSnowplowSetup)

    return () => {
      document.removeEventListener(SNOWPLOW_IS_SETUP, handleSnowplowSetup)
    }
  }, [])

  return (
    <SnowplowContext.Provider value={{ isSnowplowSetup }}>
      {children}
    </SnowplowContext.Provider>
  )
}

SnowplowProvider.propTypes = {
  children: PropTypes.any.isRequired
}

import clsx from 'clsx'

import { IContainerProps } from './types'

const Container = ({
  children,
  fullWidth = false,
  padding = true
}: IContainerProps): React.ReactElement => (
  <div
    className={clsx(
      'w-full min-w-[320px] mx-auto my-0 lg:p-0',
      fullWidth ? 'max-w-none' : 'max-w-[1250px]',
      padding ? 'px-[0.5em]' : 'p-0'
    )}
  >
    {children}
  </div>
)

export default Container

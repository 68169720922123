import { CCGS } from '@constants/closed_consumer_groups'
import { isValidCCG } from '@helpers/closed_consumer_groups'

export const getBaseFolderFromPath = (path) =>
  path?.split('/')[1]?.toLowerCase() ?? ''

export const parseCCGFromBaseFolder = (baseFolder) =>
  /^([a-z]+)-discount$/.exec(baseFolder)?.[1]

export const getCCGFromPath = (path) => {
  const baseFolder = getBaseFolderFromPath(path)
  const ccgMatch = parseCCGFromBaseFolder(baseFolder)

  if (isValidCCG(ccgMatch)) {
    return ccgMatch
  }

  return CCGS.STUDENT
}

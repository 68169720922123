'use client'

import { usePathname } from 'next/navigation'
import PropTypes from 'prop-types'

import ShortArrow from '@thebeansgroup/ui/icons/short_arrow'
import Typography from '@thebeansgroup/ui/typography'

import BrowserExtBanner from '@components/browser_ext_banner'
import {
  getPromoBanner,
  getPromoBannerData,
  hasPromoBackgroundImage
} from '@helpers/campaigns'
import { useCCG } from '@hooks/use_ccg'

import { LINK_SOURCE_SITEWIDE, PROMO_TYPE_SITEWIDE } from './constants'
import { showCampaignBanner } from './helpers'

const CampaignBanner = ({ promoBlocks }) => {
  const pathname = usePathname()
  const { isGradBeansPage } = useCCG()
  const promoData = getPromoBannerData(promoBlocks, PROMO_TYPE_SITEWIDE)

  if (!showCampaignBanner(promoData, isGradBeansPage, pathname))
    return <BrowserExtBanner />

  const { url, text, styleConfig } = getPromoBanner(
    promoData,
    LINK_SOURCE_SITEWIDE
  )

  const renderGradient = () => {
    if (!styleConfig?.gradient) return null

    return (
      <div
        data-testid='sitewide-banner-gradient'
        role='presentation'
        className={`${styleConfig?.gradient} absolute top-1/2 transform -translate-y-1/2 h-[1030px] w-full z-0`}
      />
    )
  }

  const renderBackgroundImage = () => {
    if (!hasPromoBackgroundImage(styleConfig?.image)) return null

    const desktopBg = styleConfig?.image?.desktop?.png
    const mobileBg = styleConfig?.image?.mobile?.png

    return (
      <div
        data-testid='sitewide-banner-bg-image'
        role='presentation'
        style={{
          '--sitewide-banner-desktop-image': `url(${desktopBg})`,
          '--sitewide-banner-mobile-image': `url(${mobileBg})`
        }}
        className={`
          absolute w-full h-7 z-1 bg-no-repeat bg-center bg-[auto_40px] bg-[image:var(--sitewide-banner-mobile-image)]
          lg:bg-[image:var(--sitewide-banner-desktop-image)]
        `}
      />
    )
  }

  return (
    <a
      href={url}
      style={{
        '--sitewide-banner-background-colour': styleConfig?.backgroundColour
      }}
      className='flex items-center relative w-full h-7 overflow-hidden bg-[var(--sitewide-banner-background-colour)]'
    >
      <div className='relative flex-center gap-3 w-full z-2'>
        <Typography
          component='h2'
          fontWeight='semibold'
          variant='headline'
          className={`${styleConfig?.textColour}`}
        >
          {text}
        </Typography>
        <ShortArrow className={styleConfig?.iconColour} />
      </div>
      {renderBackgroundImage()}
      {renderGradient()}
    </a>
  )
}

CampaignBanner.propTypes = {
  promoBlocks: PropTypes.array
}

CampaignBanner.defaultProps = {
  promoBlocks: []
}

export default CampaignBanner

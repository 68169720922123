/* istanbul ignore file */
import Script from 'next/script'
import { Fragment } from 'react'

import { useCountryCode } from '@hooks/use_country_code'
import { useUserData } from '@hooks/use_user_data'

import { shouldLoadScript, handleScriptLoad } from './helpers'

const ZendeskChat = () => {
  const { data, loading } = useUserData()
  const country = useCountryCode()

  if (!shouldLoadScript(loading, data, country)) return null

  return (
    <Fragment>
      <Script id='ze-settings'>
        {`
          window.zESettings = {
            webWidget: {
              launcher: {
                label: {
                  '*': 'Code not working?',
                }
              },
              contactForm: {
                title: {
                  '*': 'Beans Support'
                },
                fields: [
                  { id: 'description', prefill: { '*': 'The problem with my code is:' } },
                ]
              }
            }
          };
        `}
      </Script>
      <Script
        id='ze-snippet'
        src='https://static.zdassets.com/ekr/snippet.js?key=b175fe89-6137-4aeb-bec3-857467bb12f5'
        strategy='lazyOnload'
        onLoad={() => handleScriptLoad(data)}
      />
    </Fragment>
  )
}

export default ZendeskChat

import { gql } from '@apollo/client'

export const EMPTY_INPUT = ''

export const ZENDESK_VIEWER_FRAGMENT = gql`
  fragment ZendeskViewer on AccountsViewer {
    isLoggedIn
    userProfile {
      email
      firstName
      lastName
    }
  }
`

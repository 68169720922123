import { gql } from '@apollo/client'
import PropTypes from 'prop-types'

export const BLOCK_TYPES = {
  HEADER: 'header',
  BACKGROUND: 'background',
  CTA: 'cta'
}

export const MODULE_TYPES = {
  STANDARD_OFFERS: 'standard_collection',
  PREMIUM_OFFERS: 'premium_collection',
  IMAGE: 'image',
  HERO: 'hero'
}

export const TYPE_CAMPAIGN = 'campaign'
export const TYPE_COLLECTION = 'collection'
export const TYPE_OFFER = 'offer'
export const TYPE_EDITORIAL = 'editorial'

export const GRADIENTS_MAP = {
  cosmosGradient: 'gradient-cosmos',
  lavenderFieldGradient: 'gradient-lavender-field',
  northernLightsGradient: 'gradient-northern-lights',
  rainbowGradient: 'gradient-rainbow',
  reefGradient: 'gradient-reef',
  spaceJamGradient: 'gradient-space-jam',
  summerFieldGradient: 'gradient-summer-fields',
  violetFallGradient: 'gradient-violet-fall'
}

export const TEXT_COLOUR_MAP = {
  midnight: 'text-midnight-500',
  violet: 'text-violet-500',
  white: 'text-white-300'
}

export const ICON_COLOUR_MAP = {
  midnight: 'stroke-midnight-500',
  violet: 'stroke-violet-500',
  white: 'stroke-white-300'
}

export const CAMPAIGN_STATUS = {
  ended: 'ended',
  live: 'live',
  scheduled: 'scheduled'
}

export const PROMO_BANNER_VIEWER_FRAGMENT = gql`
  fragment PromoBannerViewer on AccountsViewer {
    promoBlocks(countrySlug: $countryCode, platform: WEB, useKevel: false) {
      edges {
        node {
          promoBlockType
          promoItems {
            edges {
              node {
                title
                description
                stylingConfig
                ... on CampaignPromo {
                  campaign {
                    campaignSlug
                    name
                    countrySlug
                    startDate
                    endDate
                    status
                  }
                }
                ... on CollectionPromo {
                  collection {
                    slug
                    name
                    countrySlug
                    startDate
                    endDate
                    status
                  }
                }
                ... on OfferPromo {
                  offer {
                    slug
                    title
                    startDate
                    endDate
                    status
                    brand {
                      slug
                    }
                    country {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const PROMO_ITEM_PROP_TYPES = PropTypes.shape({
  node: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
    cta: PropTypes.string,
    stylingConfig: PropTypes.string.isRequired,
    collection: PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
      countrySlug: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.string
    }),
    campaign: PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
      countrySlug: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.string
    }),
    offer: PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.string,
      brand: PropTypes.shape({
        slug: PropTypes.string
      }),
      country: PropTypes.shape({
        slug: PropTypes.string
      })
    })
  })
})

export const PROMO_BLOCKS_PROP_TYPES = PropTypes.arrayOf(
  PropTypes.shape({
    node: PropTypes.shape({
      promoBlockType: PropTypes.string.isRequired,
      promoItems: PROMO_ITEM_PROP_TYPES.isRequired
    })
  })
)

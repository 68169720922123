import { gql } from '@apollo/client'

import { HEADER_TOP_VIEWER_FRAGMENT } from '@components/header_top/constants'

export const HEADER_VIEWER_FRAGMENT = gql`
  fragment HeaderViewer on AccountsViewer {
    ...HeaderTopViewer
  }
  ${HEADER_TOP_VIEWER_FRAGMENT}
`

export const CURRENT_EVENT_NAV_TOGGLE = 'js-totoro-event-nav'

import { gql } from '@apollo/client'

import { HEADER_VIEWER_FRAGMENT } from '@components/header/constants'
import { ZENDESK_VIEWER_FRAGMENT } from '@components/zendesk/constants'
import { PROMO_BANNER_VIEWER_FRAGMENT } from '@constants/campaigns'

export const PAGE_VIEWER_PROMO_BANNER_FRAGMENT = gql`
  fragment PageViewerPromoBanner on AccountsViewer {
    ...PromoBannerViewer
  }
  ${PROMO_BANNER_VIEWER_FRAGMENT}
`

export const PAGE_VIEWER_HEADER_FRAGMENT = gql`
  fragment PageViewerHeader on AccountsViewer {
    ...HeaderViewer
    ...ZendeskViewer
  }
  ${HEADER_VIEWER_FRAGMENT}
  ${ZENDESK_VIEWER_FRAGMENT}
`
